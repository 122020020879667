<template>
  <vx-card class="cash-box-menu-page main-box top-zero-radius"
           v-if="cashBox && Object.keys(cashBox).length > 0">

    <profile-main-box :profile="getCashBoxProfile"
                      :default-action="profileStatus"
                      :actions-list="actionsList"
                      @transactionsTreasuryCashBox="profileStatus = 'transactionsTreasuryCashBox'"
                      @activitiesLogCashBox="profileStatus = 'activitiesLogCashBox'">
      <cash-box-transactions v-if="profileStatus === 'transactionsTreasuryCashBox'" :cashbox-id="parseInt($route.params.id)" />

      <cash-box-activities-log v-else-if="profileStatus === 'activitiesLogCashBox'" />

      <edit-cash-box v-else-if="profileStatus === 'editCashBox'" :cash-box-id="parseInt($route.params.id)" />
    </profile-main-box>
    <button id="editCashbox" v-show="false" @click="profileStatus = 'editCashBox'"></button>
  </vx-card>
</template>

<script>
import {mapGetters} from "vuex";
import {getCashBox} from '@/http/requests/treasury/cashBoxes'
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
import {customParseFloat} from "../../../../../../assets/js/functions";
import CashBoxActivitiesLog from "../activitiesLog/cashBoxActivitiesLog.vue";

export default {
  name: 'cashBoxMenu',
  components: {
    CashBoxActivitiesLog,
    EditCashBox: () => import("../edit/editCashBox.vue"),
    CashBoxTransactions: () => import("../transactions/cashBoxTransactions.vue"),
    ProfileMainBox
  },
  metaInfo () {
    return {
      title: this.cashBox.hasOwnProperty('name') ? this.$t('treasury.cashBoxes.cashBox.dynamicTitle', {cashBox: this.cashBox.name || ''}) : this.$t('treasury.cashBoxes.cashBox.title')
    }
  },
  props: {
    cashBoxId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      profileStatus: 'transactionsTreasuryCashBox',
      cashBox: {},
      actionsList: [
        {
          event: 'transactionsTreasuryCashBox',
          name: 'transactionsTreasuryCashBox',
          i18n: 'treasury.cashBoxes.cashBox.actions.transactions',
          icon: 'icon-layers',
          iconPack: 'feather'
        },
        {
          event: 'activitiesLogCashBox',
          name: 'activitiesLogCashBox',
          params: {id: this.cashBoxId || this.$route.params.id},
          header: this.$t('treasury.cashBoxes.cashBox.actions.logs'),
          i18n: '',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        }
      ],
      actions: {
        toolbar: [
          {
            id: 'editCashbox',
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            permission: 'cash.update'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'treasuryCashBoxes'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    getCashBoxProfile () {
      return {
        avatar: this.cashBox.avatar ? this.cashBox.avatar : require('@/assets/images/pages/cashbox.jpg'),
        name: this.cashBox.name || '',
        details: [],
        importantData: [
          {
            key: this.$t('treasury.cashBoxes.cashBox.importantData.openingBalance'),
            value: this.cashBox.opening_inventory ? this.cashBox.opening_inventory : 0,
            class: 'text-success',
            type: 'price'
          },
          {
            key: this.$t('treasury.cashBoxes.cashBox.importantData.transactionsCount'),
            value: this.cashBox.transaction_count || 0,
            class: 'text-primary'
          }
        ]
      }
    }
  },
  created () {
    if (this.isMobile) {
      this.actions.toolbar[0].id = {name: 'editTreasuryCashBox', params: {id: this.$route.params.id}}
      this.actions.toolbar[0].type = 'link'
    }
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
    }, 50)

    this.getCashBox()
  },
  methods: {
    getCashBox () {
      getCashBox(this.cashBoxId || this.$route.params.id).then(response => {
        this.cashBox = response.data.data
        this.$store.dispatch('setPageTitle', this.$t('treasury.cashBoxes.cashBox.dynamicTitle', {cashBox: this.cashBox.name}))

        const balance_index = this.actionsList.map(e => e.event).indexOf('transactionsTreasuryCashBox')
        if (balance_index > -1) {
          this.actionsList[balance_index].extra = {
            value: customParseFloat(this.cashBox.balance || 0),
            type: 'price'
          }
        }
      })
    },

    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.cash-box-menu-page {

  &.in-modal {
    box-shadow: none;

    .vx-card__body {
      padding: 0.5rem;
    }
  }

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
